table {
  text-align: center;
  display: flex;
  justify-content: center;
  break-inside: avoid;

  td {
    border: 1px dashed;
    background-color: #ffffff;
    width: 85mm;
    height: 55mm;

    span.ad {
      position: absolute;
      font-size: small;
      transform: rotate(-90deg) translateY(-5.6cm) translateX(3cm);
      color: gray;
      font-style: italic;
    }

    h4 {
      font-family: Arial, sans-serif;
      margin-top: 0;
      margin-bottom: 2mm;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 3mm;
      font-family: Arial, sans-serif;
      font-weight: bold;
    }

    img {
      margin: auto;
      display: block;
      height: 150px;
      width: 150px;
      @media print {
        height: 4cm;
        width: 4cm;
      }
    }

    p {
      font-size: small;
      margin: 0;
      padding: 0;
    }

    p {
      &.name {
        font-weight: bold;
      }

      &.dob {
        line-height: 2mm;
        font-size: 3mm;
        margin-bottom: 2mm;
      }
    }
  }

  table.infos {
    font-size: xx-small;

    td {
      border: 0;
      height: auto;
      width: auto;
    }

    tr {
      :first-child {
        text-align: left;
        font-size: 8px;
      }

      :last-child {
        text-align: right;
        font-weight: bold;
      }
    }
  }

  p.fingerprint {
    padding-top: 8px;
    font-size: 8px;
  }
}

.uci {
  font-size: 7px;
}

canvas.bwipjs {
  height: 0;
  width: 0;
  margin: 0;
}
