@import "node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media screen {
    background-color: #e5e9f0;
  }
}

.noprint {
  @media print {
    visibility: hidden;
    height: 0;
  }
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.close {
  visibility: hidden;
}

textarea {
  white-space: nowrap;
  overflow: auto;
}

.en {
  color: gray;
  & p {
    font-size: small;
  }
}

